import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Sustain great science with art from real research</h1>
    <div>
      <button>Donate now</button>
    </div>
    <div>  
      <button>See how it works</button>
    </div>
    <div id="howItWorks">
      <p>Donor Mint provides a gallery of digital art created from active research projects by leading scientific nonprofits.</p>
      <p>Purchase custom physical and digital fine art and merchandise to support the work of these institutions.</p>
      <p>All works include a digital certficiate of authenticity, a unique asset which can be reinvested in several leading digital marketplaces and exchanges to yield additional value to you and the scientific organizations you choose to support.</p>
    </div>
    <div id="featuredBuyNow" style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/case-studies/">Explore research</Link>
  </Layout>
)

export default IndexPage
